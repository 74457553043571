import { map, pluck } from 'rxjs/operators';
// sofe
import { fetchAsObservable } from 'fetcher!sofe';
// project
import { preprocessTemplates } from 'src/common/common.helpers.js';

export function getTeamMembers() {
  return fetchAsObservable(`/wg/users?role=TeamMember`).pipe(pluck('users'));
}

export function getNoticeTypes(search, hideOverridden = false) {
  let queryParams = '?service_type=notice&canopy_templates=true';
  if (search !== '' && search !== undefined) {
    queryParams = `${queryParams}&template_task_name_filter=${encodeURIComponent(search)}`;
  }

  if (!hideOverridden) {
    return fetchAsObservable(`api/template-tasks${queryParams}`).pipe(pluck('template_tasks'));
  } else {
    return fetchAsObservable(`api/template-tasks${queryParams}`).pipe(
      pluck('template_tasks'),
      map(results => {
        return preprocessTemplates(results);
      })
    );
  }
}
