import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { CpIcon, CpDropdown, CpButton } from 'canopy-styleguide!sofe';
import { Scoped, k } from 'kremling';
import { get } from 'lodash';
import { notifyAnalytics } from './task-methods.resource';

const LinkedFileCard = React.lazy(() => SystemJS.import('docs-ui!sofe').then(m => m.LinkedFileCardFn()));

export default class FileCard extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    handleEditFile: PropTypes.func,
    handleRemoveFile: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool,
    relationshipType: PropTypes.string
  };

  handleDownloadFile = document => {
    window.open(`/wg/documents/${document.id}/download`);
  };

  handlePrintFile = document => {
    const fileName = get(this.props, 'file.filename', 'Untitled document');

    const printWindow = window.open(`/wg/documents/${document.id}/print/${encodeURIComponent(fileName)}`);

    printWindow.onload = () => {
      printWindow.print();
    };
  };

  handleDownloadForm = file => {
    notifyAnalytics('Notice.form_downloaded_template.1.0', {
      form: file.taxForm
    });

    window.open(
      `/wg/versions/${this.props.revision.version}-${this.props.revision.id}/tax-forms/${file.taxForm}/pdf-versions/${
        this.props.revision.pdfVersions[file.taxForm]
      }/files?action=download`
    );
  };

  handlePreviewForm = file => {
    notifyAnalytics('Notice.form_previewed_template.1.0', {
      form: file.taxForm
    });

    window.open(
      `/wg/versions/${this.props.revision.version}-${this.props.revision.id}/tax-forms/${file.taxForm}/pdf-versions/${
        this.props.revision.pdfVersions[file.taxForm]
      }/files?action=print`
    );
  };

  render() {
    const { file, handleEditFile, handleRemoveFile, viewOnly, relationshipType } = this.props;

    return (
      <Scoped css={css}>
        {relationshipType === 'document_template' && (
          <div
            className="file-card"
            style={{ cursor: viewOnly ? 'default' : 'pointer' }}
            onClick={() => {
              if (!viewOnly) {
                handleEditFile(file);
              }
            }}>
            <div style={{ display: 'flex', paddingLeft: 8 }}>
              <CpIcon name="file-document" fill="var(--cp-color-app-link-text)" />
              <div className="fc-file-name">{file.filename.replace('.docx', '')}</div>
            </div>
            <CpDropdown
              position="bottom-end"
              contentWidth={200}
              renderTrigger={({ toggle }) => (
                <CpButton
                  icon="misc-kabob"
                  onClick={e => {
                    e.stopPropagation();
                    toggle();
                  }}
                  aria-label="file-options"
                />
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  {!viewOnly && (
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        handleEditFile(file);
                      }}>
                      <CpIcon name="crud-pencil" className="cp-select-list__icon-left" />
                      Edit
                    </button>
                  )}
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      this.handleDownloadFile(file);
                    }}>
                    <CpIcon name="af-open-down" className="cp-select-list__icon-left" />
                    Download file
                  </button>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      this.handlePrintFile(file);
                    }}>
                    <CpIcon name="misc-printer" className="cp-select-list__icon-left" />
                    Print file
                  </button>
                  {!viewOnly && (
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        handleRemoveFile(file);
                      }}>
                      <CpIcon name="remove-circle-open-large" className="cp-select-list__icon-left" />
                      Remove document
                    </button>
                  )}
                </div>
              )}
            />
          </div>
        )}
        {relationshipType === 'form' && (
          <div className="file-card">
            <Suspense fallback={null}>
              <LinkedFileCard file={{ ...file, type: 'pdf' }} cardClass="fc-linked-custom" cardWidth="100%" disablePreview />
              {!viewOnly && (
                <CpDropdown
                  position="bottom-end"
                  contentWidth={190}
                  renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="file-options" />}
                  renderContent={() => (
                    <Scoped css={css}>
                      <div className="fc-action-item" onClick={() => this.handleDownloadForm(file)}>
                        <CpIcon name="af-open-down" className="fc-action" />
                        Download form
                      </div>
                      <div className="fc-action-item" onClick={() => this.handlePreviewForm(file)}>
                        <CpIcon name="file-pdf" className="fc-action" />
                        Preview form
                      </div>
                      <div className="fc-action-item" onClick={() => handleRemoveFile(file)}>
                        <CpIcon name="remove-circle-open-large" className="fc-action" />
                        Remove form
                      </div>
                    </Scoped>
                  )}
                />
              )}
            </Suspense>
          </div>
        )}
        {relationshipType === 'file' && (
          <div className="file-card">
            <Suspense fallback={null}>
              <LinkedFileCard file={file} cardClass="fc-linked-custom" cardWidth="100%" />
              {!viewOnly && (
                <CpDropdown
                  position="bottom-end"
                  contentWidth={190}
                  renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="file-options" />}
                  renderContent={() => (
                    <Scoped css={css}>
                      <div className="fc-action-item" onClick={() => handleRemoveFile(file)}>
                        <CpIcon name="remove-circle-open-large" className="fc-action" />
                        Remove file
                      </div>
                    </Scoped>
                  )}
                />
              )}
            </Suspense>
          </div>
        )}
      </Scoped>
    );
  }
}

FileCard.defaultProps = {
  viewOnly: true
};

const css = k`
  .file-card {
    height: 40px;
    width: 27rem;
    margin-bottom: 8px;
    margin-right: 12px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    box-shadow: rgb(75, 78, 83) 0px 10px 23px -18px;
    background: white;
    padding: 0px 12px 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(223, 223, 223);
    border-image: initial;
  }

  .file-card .fc-linked-custom {
    border: none;
    box-shadow: none;
    padding-right: 0;
    height: 32px;
    margin-right: 0;
    background: transparent;
    margin-bottom: 0;
  }

  .fc-file-name {
    margin-left: 4px;
    margin-top: 4px;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fc-action-item {
    padding: 8px 8px 4px;
    cursor: pointer;
  }

  .fc-action-item:hover {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .fc-action {
    padding-right: 8px;
    margin-bottom: 4px;
  }
`;
