import { pluck, switchMap } from 'rxjs/operators';
import { uniqueId } from 'lodash';

// sofe imports
import { fetchAsObservable } from 'fetcher!sofe';

// project
import { saveNoticeTemplate } from './template-editor/template-editor.resource.js';

export function deleteNoticeTemplate(templateId) {
  if (!templateId) {
    throw new Error('Cannot delete a notice template without a valid template id');
  }
  return fetchAsObservable(`/api/template-tasks/${templateId}`, {
    method: 'DELETE'
  });
}

export function fetchTemplate(templateId) {
  if (!templateId) {
    throw new Error('cannot fetch template without a templateId');
  }

  return fetchAsObservable(`/api/template-tasks/${templateId}?include=files,document_templates,forms`).pipe(pluck('template_tasks'));
}

export function copyAndSaveNoticeTemplate(templateId, newName) {
  return fetchTemplate(templateId).pipe(
    switchMap(template => {
      template.id = uniqueId('new_template');
      template.name = newName.trim();
      template.service_fields.notice_type = newName.trim();
      template.is_draft = true;
      return saveNoticeTemplate(template, false, true);
    })
  );
}

export function customizeTemplate(templateId) {
  return fetchAsObservable(`/api/template-tasks/${templateId}/copy`, {
    method: 'POST'
  }).pipe(pluck('template_tasks'));
}
